import React from 'react'

function Home() {
  return (
    <>
      <div data-spy="scroll" data-target=".navbar" data-offset={40} id="home">
        <header className="header">
          <div className="container">
            <ul className="social-icons pt-3">
              <li className="social-item"><a className="social-link" href="https://www.linkedin.com/in/shushanta-pudasaini-baa7b0134/"><i className="ti-linkedin" aria-hidden="true" /></a></li>
              <li className="social-item"><a className="social-link" href="https://medium.com/@shushanta574"><i className="ti-text" aria-hidden="true" /></a></li>
              <li className="social-item"><a className="social-link" href="https://scholar.google.com/citations?user=mBvpJ0MAAAAJ&hl=en&oi=ao"><i className="ti-google" aria-hidden="true" /></a></li>
              {/* <li className="social-item"><a className="social-link" href="#"><i className="ti-instagram" aria-hidden="true" /></a></li> */}
              <li className="social-item"><a className="social-link" href="https://github.com/pudasainishushant"><i className="ti-github" aria-hidden="true" /></a></li>
            </ul>
            <div className="header-content">
              <h4 className="header-subtitle">Hello, I am</h4>
              <h1 className="header-title">Shushant Pudasaini</h1>
              <h6 className="header-mono"> Machine Learning Engineer , Lecturer, Researcher, Blogger </h6>
              <button className="btn btn-primary btn-rounded"><i className="ti-printer pr-2" />Print Resume</button>
            </div>
          </div>
        </header>
        <nav className="navbar sticky-top navbar-expand-lg navbar-light" data-spy="affix" data-offset-top={510} style={{ backgroundColor: "#F4DAD4" }}>
          <div className="container">
            <button className="navbar-toggler ml-auto" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse mt-sm-20 navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <a href="#home" className="nav-link">Home</a>
                </li>
                <li className="nav-item">
                  <a href="#about" className="nav-link">About</a>
                </li>
                <li className="nav-item">
                  <a href="#resume" className="nav-link">Resume</a>
                </li>
              </ul>
              <ul className="navbar-nav brand">
                <img src="assets/imgs/profile_shushant.jpg" alt="" className="brand-img" height={'180vh'} />
                <li className="brand-txt">
                  <h5 className="brand-title">Shushanta Pudasaini</h5>
                  <div className="brand-subtitle">Machine Learning Engineer , Lecturer, Researcher, Blogger</div>
                </li>
              </ul>
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <a href="#portfolio" className="nav-link">Portfolio</a>
                </li>
                <li className="nav-item">
                  <a href="#blog" className="nav-link">Blog</a>
                </li>
                <li className="nav-item last-item">
                  <a href="#contact" className="nav-link">Contact</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div className="container-fluid" style={{ backgroundColor: '#D3D0D0' }}>
          <div id="about" className="row about-section">
            <div className="col-lg-4 about-card">
              <h3 className="font-weight-light">About Me</h3>
              <span className="line mb-4" />
              <li><span style={{ fontWeight: 'bold' }}>Passionate Data Scientist and Researcher</span> : Data Analysis, Artificial Intelligence, Data Visualization and Machine Learning Algorithms</li>
              <li><span style={{ fontWeight: 'bold' }}>Teacher</span> : Education on AI and Data Science</li>
              <li><span style={{ fontWeight: 'bold' }}>Blogger</span> : Articles on Medium about Related Field</li>
              <li><span style={{ fontWeight: 'bold' }}>Other</span> : Freelancing Services and Consulting Services</li>
              <button className="btn btn-outline-danger"><i className="icon-down-circled2 " />Download My CV</button>
            </div>
            <div className="col-lg-4 about-card">
              <h3 className="font-weight-light">Personal Info</h3>
              <span className="line mb-4" />
              <ul className="mt40 info list-unstyled">
                <li><span>Birthdate</span> : 03/10/1995 (DD-MM-YYYY)</li>
                <li><span>Email</span> : shushanta574@gmail.com</li>
                <li><span>Phone</span> : +977-9843698538</li>
                <li><span>Address</span> :  Kathmandu, Nepal</li>
                <li><span>Interests</span> :  Football, Travelling</li>
                <li><span>Maritial Status</span> :  Single</li>
              </ul>
              <ul className="social-icons pt-3">
                <li className="social-item"><a className="social-link" href="https://www.linkedin.com/in/shushanta-pudasaini-baa7b0134/"><i className="ti-linkedin" aria-hidden="true" /></a></li>
                <li className="social-item"><a className="social-link" href="https://medium.com/@shushanta574"><i className="ti-text" aria-hidden="true" /></a></li>
                <li className="social-item"><a className="social-link" href="https://scholar.google.com/citations?user=mBvpJ0MAAAAJ&hl=en&oi=ao"><i className="ti-google" aria-hidden="true" /></a></li>
                {/* <li className="social-item"><a className="social-link" href="#"><i className="ti-instagram" aria-hidden="true" /></a></li> */}
                <li className="social-item"><a className="social-link" href="https://github.com/pudasainishushant"><i className="ti-github" aria-hidden="true" /></a></li>
              </ul>
            </div>
            <div className="col-lg-4 about-card">
              <h3 className="font-weight-light">My Expertise</h3>
              <span className="line mb-4" />
              <div className="row">
                <div className="col-1 text-danger"><i className="ti-dashboard icon-lg" /></div>
                <div className="col-10 ml-auto mr-3">
                  <h6>Engineer and Researcher</h6>
                  <p className="subtitle"> FuseMachines Pvt. Ltd. & InfoDevelopers Pvt. Ltd.</p>
                  <hr />
                </div>
              </div>
              <div className="row">
                <div className="col-1 text-danger"><i className="ti-paragraph icon-lg" /></div>
                <div className="col-10 ml-auto mr-3">
                  <h6>Teacher</h6>
                  <p className="subtitle">Teaching Assistant  | Fusemachines & AI Lecturer  | Herald College</p>
                  <hr />
                </div>
              </div>
              <div className="row">
                <div className="col-1 text-danger"><i className="ti-ink-pen icon-lg" /></div>
                <div className="col-10 ml-auto mr-3">
                  <h6>Blogger</h6>
                  <p className="subtitle">Medium</p>
                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Resume Section*/}
        <section className="section" id="resume" style={{ backgroundColor: "#F4DAD4" }}>
          <div className="container">
            <h2 className="mb-5"><span className="text-danger">My</span> Resume</h2>
            <div className="row">
              <div className="col-md-6 col-lg-4">
                <div className="card">
                  <div className="card-header">
                    <div className="mt-2">
                      <h4>Industry Experience</h4>
                      <span className="line" />
                    </div>
                  </div>
                  <div className="card-body">
                    <h6 className="title text-danger">17/01/2021 - Present</h6>
                    <p>ML Engineer Level II  | Fusemachines Pvt. Ltd.</p>
                    <p className="subtitle"><li>Development and deployment of NLP based services for job portals. <a href="https://squadery.com/">Click Here For Demo Link</a></li></p>
                    <p className="subtitle">
                      <li>Working on bioinformatics research project - WINKS</li>
                    </p>
                    <hr />


                    <h6 className="title text-danger">01/04/2019- 01/03/2021</h6>
                    <p>Senior AI Engineer  | Infodevelopers Pvt Ltd</p>
                    <p className="subtitle"><li>Developed several chatbots for banking organizations, and government projects. <a href="https://vyccu.org.np/">Click Here For Demo Link</a></li></p>
                    <p className="subtitle">
                      <li>Worked on resume parsing and ranking project for job portal site.<a href="https://dolphwin.com/#/">Click Here For Demo Link</a></li>
                    </p>
                    <p className="subtitle">
                      <li>Built inhouse attendance system using face recognition.<a href="#">Currently in Production using Raspberry Pi and Real Sense Camera</a></li>
                    </p>
                    <p className="subtitle">
                      <li>Worked in data science-based solutions for government websites.<a href="https://opcr.nepalpolice.gov.np/#/login">Click Here For Demo Link</a></li>
                    </p>
                    <p className="subtitle">
                      <li>Deployment of AI Products using technologies such as Flask, Docker, AWS, nginx</li>
                    </p>
                    <hr />





                    <h6 className="title text-danger">01/03/2021 –01/09/2021</h6>
                    <p>AI Supervisor  | National Innovation Center (NIC)</p>
                    <p className="subtitle"><li>Working in MINA framework for leveraging computer vision based solutions like object detection, object tracking, face recognition for robotics projects in NIC.</li></p>
                    <p className="subtitle">
                      <li>Supervise AI team of NIC for developing AI solutions to be used in robotics projects.</li>
                    </p>
                    <hr />

                    <h6 className="title text-danger">01/08/2019 – 01/01/2021 </h6>
                    <p>AI Developer  | IT Sutra </p>
                    <p className="subtitle"><li>Developed web application using Django for providing smart attendance system using face-api provided by Microsoft Azure Cognitive service  Consult and Supervise on AI projects.</li></p>
                    <p className="subtitle">
                      <li>Supervise AI team of NIC for developing AI solutions to be used in robotics projects.</li>
                    </p>
                    <hr />

                    <h6 className="title text-danger">01/08/2018 – 01/04/2019</h6>
                    <p>Data Analyst  | Datalytics Pvt Ltd </p>
                    <p className="subtitle"><li>Data visualization using MATLAB.
                      Data Analysis using SPSS tool
                      Prepared technical research papers using LATEX.
                      Developed automation scripts using Python
                    </li></p>
                    <hr />

                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4">
                <div className="card">
                  <div className="card-header">
                    <div className="mt-2">
                      <h4>Academic Experience</h4>
                      <span className="line" />
                    </div>
                  </div>
                  <div className="card-body">
                    <h6 className="title text-danger">01/07/2019 – 01/12/2019</h6>
                    <p>AI Lecturer  | Herald College</p>
                    <p className="subtitle"><li>Taught an AI course to Bachelors of Information Technology (BIT) students and supervised their final year projects.</li></p>
                    <p className="subtitle"><li>Taught an AI course to Bachelors of Information Technology (BIT) students and supervised their final year projects.</li></p>
                    <hr />
                    <h6 className="title text-danger">01/07/2019 – 01/12/2019</h6>
                    <p>Teaching Assistant  | Fusemachines</p>
                    <p className="subtitle"><li>Taught students Machine Learning course in  AI Fellowship program.<a href="https://www.fuse.ai/ai-fellowship/">Click Here For Demo Link</a></li> </p>
                    <p className="subtitle"><li>Taught students Machine Learning course in  AI Fellowship program.<a href="https://www.fuse.ai/ai-fellowship/">Click Here For Demo Link</a></li> </p>
                    <hr />
                    <h6 className="title text-danger">01/07/2019 – 01/12/2019</h6>
                    <p>Visiting  Lecturer  | Herald College </p>
                    <p className="subtitle"><li>Taught an AI course to BsCSIT  students and supervised their final year projects. </li></p>
                    <p className="subtitle"><li>Taught an AI course to BsCSIT  students and supervised their final year projects. </li></p>
                    <hr />
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <div className="mt-2">
                      <h4>Education</h4>
                      <span className="line" />
                    </div>
                  </div>
                  <div className="card-body">
                    <h6 className="title text-danger">2014 – 2018</h6>
                    <p>Bachelor's in Computer Engineering | ACEM , TU </p>
                    <p className="subtitle"><li>Database Management System, Operating System, Artificial Intelligence and Many More</li></p>
                    <p className="subtitle"><li>Attendace System Project</li></p>
                    <p className="subtitle"><li>Share Market Prediction System</li></p>
                    <hr />
                    <h6 className="title text-danger">2012 - 2014</h6>
                    <p>HSEB (+2)</p>
                    <p className="subtitle"><li>Graduated from Kathmandu Model College (78%)</li></p>
                    <hr />
                    <h6 className="title text-danger">2012 batch</h6>
                    <p>SLC</p>
                    <p className="subtitle"><li>Graduated from GEMS (86%)</li></p>
                    <p className="subtitle"><li>Graduated from GEMS (86%)</li></p>
                    <hr />
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="card">
                  <div className="card-header">
                    <div className="pull-left">
                      <h4 className="mt-2">Skills</h4>
                      <span className="line" />
                    </div>
                  </div>
                  <div className="card-body pb-2">
                    <h6>Python  </h6>
                    <div className="progress mb-3">
                      <div className="progress-bar bg-danger" role="progressbar" style={{ width: '97%' }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <h6>Docker</h6>
                    <div className="progress mb-3">
                      <div className="progress-bar bg-danger" role="progressbar" style={{ width: '85%' }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <h6>Flask </h6>
                    <div className="progress mb-3">
                      <div className="progress-bar bg-danger" role="progressbar" style={{ width: '80%' }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <h6>FastAPI</h6>
                    <div className="progress mb-3">
                      <div className="progress-bar bg-danger" role="progressbar" style={{ width: '90%' }} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <h6>MongoDB</h6>
                    <div className="progress mb-3">
                      <div className="progress-bar bg-danger" role="progressbar" style={{ width: '90%' }} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <h6>Tensorflow</h6>
                    <div className="progress mb-3">
                      <div className="progress-bar bg-danger" role="progressbar" style={{ width: '90%' }} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <h6>PyTorch  </h6>
                    <div className="progress mb-3">
                      <div className="progress-bar bg-danger" role="progressbar" style={{ width: '97%' }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <h6>Transformers</h6>
                    <div className="progress mb-3">
                      <div className="progress-bar bg-danger" role="progressbar" style={{ width: '85%' }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <h6>Scikit-Learn</h6>
                    <div className="progress mb-3">
                      <div className="progress-bar bg-danger" role="progressbar" style={{ width: '80%' }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <h6>PyTorch Lightning</h6>
                    <div className="progress mb-3">
                      <div className="progress-bar bg-danger" role="progressbar" style={{ width: '90%' }} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <h6>Rasa</h6>
                    <div className="progress mb-3">
                      <div className="progress-bar bg-danger" role="progressbar" style={{ width: '90%' }} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <h6>Spacy</h6>
                    <div className="progress mb-3">
                      <div className="progress-bar bg-danger" role="progressbar" style={{ width: '90%' }} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <h6>Gensim</h6>
                    <div className="progress mb-3">
                      <div className="progress-bar bg-danger" role="progressbar" style={{ width: '90%' }} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <h6>NLTK</h6>
                    <div className="progress mb-3">
                      <div className="progress-bar bg-danger" role="progressbar" style={{ width: '90%' }} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <h6>Streamlit</h6>
                    <div className="progress mb-3">
                      <div className="progress-bar bg-danger" role="progressbar" style={{ width: '90%' }} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <h6>Amazon Web Services</h6>
                    <div className="progress mb-3">
                      <div className="progress-bar bg-danger" role="progressbar" style={{ width: '90%' }} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <h6>Weights & Biases</h6>
                    <div className="progress mb-3">
                      <div className="progress-bar bg-danger" role="progressbar" style={{ width: '90%' }} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <h6>Hydra</h6>
                    <div className="progress mb-3">
                      <div className="progress-bar bg-danger" role="progressbar" style={{ width: '90%' }} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <h6>DVC</h6>
                    <div className="progress mb-3">
                      <div className="progress-bar bg-danger" role="progressbar" style={{ width: '90%' }} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <h6>Natural Language Procesisng</h6>
                    <div className="progress mb-3">
                      <div className="progress-bar bg-danger" role="progressbar" style={{ width: '90%' }} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <h6>MLoPs</h6>
                    <div className="progress mb-3">
                      <div className="progress-bar bg-danger" role="progressbar" style={{ width: '90%' }} aria-valuenow={75} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <div className="pull-left">
                      <h4 className="mt-2">Languages</h4>
                      <span className="line" />
                    </div>
                  </div>
                  <div className="card-body pb-2">
                    <h6>Nepali</h6>
                    <div className="progress mb-3">
                      <div className="progress-bar bg-danger" role="progressbar" style={{ width: '80%' }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <h6>English</h6>
                    <div className="progress mb-3">
                      <div className="progress-bar bg-danger" role="progressbar" style={{ width: '45%' }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <h6>Hindi</h6>
                    <div className="progress mb-3">
                      <div className="progress-bar bg-danger" role="progressbar" style={{ width: '67%' }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section bg-dark text-center">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-6 col-lg-3">
                <div className="row ">
                  <div className="col-5 text-right text-light border-right py-3">
                    <div className="m-auto"><i className="ti-alarm-clock icon-xl" /></div>
                  </div>
                  <div className="col-7 text-left py-3">
                    <h1 className="text-danger font-weight-bold font40">20</h1>
                    <p className="text-light mb-1">Hours Per Week (Part-Time)</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="row">
                  <div className="col-5 text-right text-light border-right py-3">
                    <div className="m-auto"><i className="ti-layers-alt icon-xl" /></div>
                  </div>
                  <div className="col-7 text-left py-3">
                    <h1 className="text-danger font-weight-bold font40">12</h1>
                    <p className="text-light mb-1">Project Finished</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="row">
                  <div className="col-5 text-right text-light border-right py-3">
                    <div className="m-auto"><i className="ti-face-smile icon-xl" /></div>
                  </div>
                  <div className="col-7 text-left py-3">
                    <h1 className="text-danger font-weight-bold font40">Many</h1>
                    <p className="text-light mb-1">Happy Clients</p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="row">
                  <div className="col-5 text-right text-light border-right py-3">
                    <div className="m-auto"><i className="ti-bell icon-xl" /></div>
                  </div>
                  <div className="col-7 text-left py-3">
                    <h1 className="text-danger font-weight-bold font40">Immediate</h1>
                    <p className="text-light mb-1">Response</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="section" id="service">
          <div className="container">
            <h2 className="mb-5 pb-4"><span className="text-danger">My</span> Services</h2>
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="card mb-5">
                  <div className="card-header has-icon">
                    <i className="ti-vector text-danger" aria-hidden="true" />
                  </div>
                  <div className="card-body px-4 py-3">
                    <h5 className="mb-3 card-title text-dark">Ullam</h5>
                    <p className="subtitle">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam commodi provident, dolores reiciendis enim pariatur error optio, tempora ex, nihil nesciunt! In praesentium sunt commodi, unde ipsam ex veritatis laboriosam dolor asperiores suscipit blanditiis, dignissimos quos nesciunt nulla aperiam officia.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="card mb-5">
                  <div className="card-header has-icon">
                    <i className="ti-write text-danger" aria-hidden="true" />
                  </div>
                  <div className="card-body px-4 py-3">
                    <h5 className="mb-3 card-title text-dark">Asperiores</h5>
                    <p className="subtitle">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam commodi provident, dolores reiciendis enim pariatur error optio, tempora ex, nihil nesciunt! In praesentium sunt commodi, unde ipsam ex veritatis laboriosam dolor asperiores suscipit blanditiis, dignissimos quos nesciunt nulla aperiam officia.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="card mb-5">
                  <div className="card-header has-icon">
                    <i className="ti-package text-danger" aria-hidden="true" />
                  </div>
                  <div className="card-body px-4 py-3">
                    <h5 className="mb-3 card-title text-dark">Tempora</h5>
                    <p className="subtitle">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam commodi provident, dolores reiciendis enim pariatur error optio, tempora ex, nihil nesciunt! In praesentium sunt commodi, unde ipsam ex veritatis laboriosam dolor asperiores suscipit blanditiis, dignissimos quos nesciunt nulla aperiam officia.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="card mb-5">
                  <div className="card-header has-icon">
                    <i className="ti-map-alt text-danger" aria-hidden="true" />
                  </div>
                  <div className="card-body px-4 py-3">
                    <h5 className="mb-3 card-title text-dark">Provident</h5>
                    <p className="subtitle">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam commodi provident, dolores reiciendis enim pariatur error optio, tempora ex, nihil nesciunt! In praesentium sunt commodi, unde ipsam ex veritatis laboriosam dolor asperiores suscipit blanditiis, dignissimos quos nesciunt nulla aperiam officia.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="card mb-5">
                  <div className="card-header has-icon">
                    <i className="ti-bar-chart text-danger" aria-hidden="true" />
                  </div>
                  <div className="card-body px-4 py-3">
                    <h5 className="mb-3 card-title text-dark">Consectetur</h5>
                    <p className="subtitle">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam commodi provident, dolores reiciendis enim pariatur error optio, tempora ex, nihil nesciunt! In praesentium sunt commodi, unde ipsam ex veritatis laboriosam dolor asperiores suscipit blanditiis, dignissimos quos nesciunt nulla aperiam officia.</p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="card mb-5">
                  <div className="card-header has-icon">
                    <i className="ti-support text-danger" aria-hidden="true" />
                  </div>
                  <div className="card-body px-4 py-3">
                    <h5 className="mb-3 card-title text-dark">Veritatis</h5>
                    <p className="subtitle">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ullam commodi provident, dolores reiciendis enim pariatur error optio, tempora ex, nihil nesciunt! In praesentium sunt commodi, unde ipsam ex veritatis laboriosam dolor asperiores suscipit blanditiis, dignissimos quos nesciunt nulla aperiam officia.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section bg-custom-gray" id="price">
          <div className="container">
            <h1 className="mb-5"><span className="text-danger">Packs</span> Pricing</h1>
            <div className="row align-items-center">
              <div className="col-md-6 col-lg-3">
                <div className="price-card text-center mb-4">
                  <h3 className="price-card-title">Free</h3>
                  <div className="price-card-cost">
                    <sup className="ti-money" />
                    <span className="num">0</span>
                    <span className="date">MO</span>
                  </div>
                  <ul className="list">
                    <li className="list-item">5 <span className="text-muted">Project</span></li>
                    <li className="list-item">1GB <span className="text-muted">Storage</span></li>
                    <li className="list-item"><span className="text-muted">No Domain</span></li>
                    <li className="list-item">1 <span className="text-muted">User</span></li>
                  </ul>
                  <button className="btn btn-primary btn-rounded w-lg">Subscribe</button>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="price-card text-center mb-4">
                  <h3 className="price-card-title">Basic</h3>
                  <div className="price-card-cost">
                    <sup className="ti-money" />
                    <span className="num">10</span>
                    <span className="date">MO</span>
                  </div>
                  <ul className="list">
                    <li className="list-item">50 <span className="text-muted">Project</span></li>
                    <li className="list-item">10GB <span className="text-muted">Storage</span></li>
                    <li className="list-item">1<span className="text-muted">Domain</span></li>
                    <li className="list-item">5 <span className="text-muted">User</span></li>
                  </ul>
                  <button className="btn btn-primary btn-rounded w-lg">Subscribe</button>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="price-card text-center price-card-requried mb-4">
                  <h3 className="price-card-title">Exclusive</h3>
                  <div className="price-card-cost">
                    <sup className="ti-money" />
                    <span className="num">25</span>
                    <span className="date">MO</span>
                  </div>
                  <ul className="list">
                    <li className="list-item">150 <span className="text-muted">Project</span></li>
                    <li className="list-item">15GB <span className="text-muted">Storage</span></li>
                    <li className="list-item">5<span className="text-muted"> Domain</span></li>
                    <li className="list-item">15<span className="text-muted">User</span></li>
                  </ul>
                  <button className="btn btn-primary btn-rounded w-lg">Subscribe</button>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="price-card text-center mb-4">
                  <h3 className="price-card-title">Pro</h3>
                  <div className="price-card-cost">
                    <sup className="ti-money" />
                    <span className="num">99</span>
                    <span className="date">MO</span>
                  </div>
                  <ul className="list">
                    <li className="list-item">500 <span className="text-muted">Project</span></li>
                    <li className="list-item">1000GB <span className="text-muted">Storage</span></li>
                    <li className="list-item">10<span className="text-muted"> Domain</span></li>
                    <li className="list-item">Unlimite<span className="text-muted">User</span></li>
                  </ul>
                  <button className="btn btn-primary btn-rounded w-lg">Subscribe</button>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className="section bg-dark py-5">
          <div className="container text-center">
            <h2 className="text-light mb-5 font-weight-normal">I Am Available For FreeLance</h2>
            <button className="btn bg-primary w-lg">Hire me</button>
          </div>
        </section>
        {/* Portfolio Section */}
        <section className="section bg-custom-gray" id="portfolio" >
          <div className="container">
            <h1 className="mb-5"><span className="text-danger">My</span> Portfolio (Projects)</h1>
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div className="card mb-5 h-100" >
                  <div className="card-header has-icon">
                    <i className="ti-vector text-danger" aria-hidden="true" />
                  </div>
                  <div className="card-body px-4 py-3">
                    <h5 className="mb-3 card-title text-dark">EverestNLP</h5>
                    <p className="subtitle"><li>NLP framework for solving Natural Language Processing problems for Devanagari (Nepali) language</li></p>
                    <p className="subtitle"><li><a href="https://github.com/pudasainishushant/everest-nlp">Repo</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <a href="https://pypi.org/project/everest-nlp/">Library</a></li></p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="card mb-5 h-100">
                  <div className="card-header has-icon">
                    <i className="ti-vector text-danger" aria-hidden="true" />
                  </div>
                  <div className="card-body px-4 py-3">
                    <h5 className="mb-3 card-title text-dark">Profanity Detection</h5>
                    <p className="subtitle"><li>Detect any profane texts whist filling form for Online Police Clearance Registration Systen and detect whether the user has uploaded valid photo of his/her or not.</li></p>
                    <p className="subtitle"><li><a href=" https://github.com/pudasainishushant/PROFANITY_DETECTION_OPCRS ">Repo</a></li></p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="card mb-5 h-100">
                  <div className="card-header has-icon">
                    <i className="ti-vector text-danger" aria-hidden="true" />
                  </div>
                  <div className="card-body px-4 py-3">
                    <h5 className="mb-3 card-title text-dark">Pretraining of Nepali Language Models</h5>
                    <p className="subtitle"><li>Develop BERT based  large masked language models and casual language models for  nepali language using transformers and deepspeed training.</li></p>
                    <p className="subtitle"><li><a href="https://github.com/pudasainishushant/NepaliLanguageModelPretraining">Repo</a></li></p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="card mb-5 h-100">
                  <div className="card-header has-icon">
                    <i className="ti-vector text-danger" aria-hidden="true" />
                  </div>
                  <div className="card-body px-4 py-3">
                    <h5 className="mb-3 card-title text-dark">Word2Vec</h5>
                    <p className="subtitle"><li>Fully automated KYC verification using webcam for any services using several NLP and CV techniques like Face detection, headpose estimation, liveness detection and information extraction.</li></p>
                    <p className="subtitle"><li><a href="https://github.com/pudasainishushant/Video-KYC ">Repo</a></li></p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="card mb-5 h-100">
                  <div className="card-header has-icon">
                    <i className="ti-vector text-danger" aria-hidden="true" />
                  </div>
                  <div className="card-body px-4 py-3">
                    <h5 className="mb-3 card-title text-dark">NepaliEmbedding</h5>
                    <p className="subtitle"><li>Python library to generate proper word embeddings for nepali text from approaches like BERT, Word2vec , Doc2vec.</li></p>
                    <p className="subtitle"><li><a href="https://github.com/pudasainishushant/nepali_embedding">Repo</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <a href="https://pypi.org/project/everest-nlp/">Library</a></li></p>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div className="card mb-5 h-100">
                  <div className="card-header has-icon">
                    <i className="ti-vector text-danger" aria-hidden="true" />
                  </div>
                  <div className="card-body px-4 py-3">
                    <h5 className="mb-3 card-title text-dark">EverestNLP</h5>
                    <p className="subtitle"><li>NLP framework for solving Natural Language Processing problems for Devanagari (Nepali) language</li></p>
                    <p className="subtitle"><li><a href="https://github.com/pudasainishushant/everest-nlp">Repo</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      <a href="https://pypi.org/project/nepali-embedding/ ">Library</a></li></p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
        {/* End of portfolio section */}
        <section className="section" id="blog" style={{ backgroundColor: "#F4DAD4" }}>
          <div className="container">
            <h2 className="mb-5">Latest <span className="text-danger">Blogs</span></h2>
            <div className="row">
              <div className="blog-card">
                <div className="img-holder">
                  <a href="https://medium.com/@shushanta574/bert-4810545a53b7"><img src="assets/imgs/blog1.jpg" alt="Download free bootstrap 4 landing page, free boootstrap 4 templates, Download free bootstrap 4.1 landing page, free boootstrap 4.1.1 templates, meyawo Landing page" /></a>
                </div>
                <div className="content-holder">
                  <a href="https://medium.com/@shushanta574/bert-4810545a53b7">
                    <h6 className="title">How does BERT work and what are the use cases of BERT ?</h6>
                    <p className="post-details">
                      By: Admin
                    </p>
                    <p>BERT makes use of Transformer, an attention mechanism that learns contextual relations between words(or sub-words) in a text.</p>
                    <p><b>Bidirectional Encoder Representations from Transformers.</b></p>
                    <p>Researcher details a novel technique named Masked LM(MLM) which allows bidirectional training in models in which it was previously impossible.</p>
                  </a>
                  <a href="https://medium.com/@shushanta574/bert-4810545a53b7" className="read-more">Read more <i className="ti-angle-double-right" /></a>
                </div>
              </div>



              <div className="blog-card">
                <div className="img-holder">
                  <a href="https://medium.com/@shushanta574/is-the-deep-learning-hype-real-and-will-deep-learning-replace-machine-learning-algorithms-2a5911aab2f1"><img src="assets/imgs/blog1.jpg" alt="Download free bootstrap 4 landing page, free boootstrap 4 templates, Download free bootstrap 4.1 landing page, free boootstrap 4.1.1 templates, meyawo Landing page" /></a>
                </div>
                <div className="content-holder">
                  <a href="https://medium.com/@shushanta574/is-the-deep-learning-hype-real-and-will-deep-learning-replace-machine-learning-algorithms-2a5911aab2f1">
                    <h6 className="title">Is the deep learning hype real and will deep learning replace machine learning algorithms?</h6>
                    <p className="post-details">
                      By: Admin
                    </p>
                    <p>I wanted to share through this article about deep learning, its use cases and possibilities.</p>
                    <p><b>What is deep learning?</b></p>
                    <p>Deep learning is a sub field of artificial intelligence that relies on the applications of neural networks on a huge amount of data.</p>
                  </a>
                  <a href="https://medium.com/@shushanta574/is-the-deep-learning-hype-real-and-will-deep-learning-replace-machine-learning-algorithms-2a5911aab2f1" className="read-more">Read more <i className="ti-angle-double-right" /></a>
                </div>
              </div>



              <div className="blog-card">
                <div className="img-holder">
                  <a href="https://medium.com/@shushanta574/problems-with-gradient-descent-and-how-to-solve-them-f2db5433db58"><img src="assets/imgs/blog1.jpg" alt="Download free bootstrap 4 landing page, free boootstrap 4 templates, Download free bootstrap 4.1 landing page, free boootstrap 4.1.1 templates, meyawo Landing page" /></a>
                </div>
                <div className="content-holder">
                  <a href="https://medium.com/@shushanta574/problems-with-gradient-descent-and-how-to-solve-them-f2db5433db58">
                    <h6 className="title">Problems with gradient descent and how to solve them?</h6>
                    <p className="post-details">
                      By: Admin
                    </p>
                    <p>Gradient descent is an iterative method. We start with some set of values for our model parameters (weights and biases), and improve them slowly.</p>
                    <p><b>These are the ways of identifying exploding gradient:</b></p>
                    <p>- Model is unstable; resulting in large changes in loss
                      <br />
                      - Model loss goes to NaN during training</p>
                  </a>
                  <a href="https://medium.com/@shushanta574/problems-with-gradient-descent-and-how-to-solve-them-f2db5433db58" className="read-more">Read more <i className="ti-angle-double-right" /></a>
                </div>
              </div>


            </div>
          </div>
        </section>
        <div className="section contact" id="contact" style={{ backgroundColor: '#D3D0D0' }}>
          <div className="container" >
            <div className="row">
              <div className="col-lg-4">
                <div className="contact-info-card" style={{ backgroundColor: '#D3D0D0' }}>
                  <h4 className="contact-title">Get in touch</h4>
                  <div className="row mb-2">
                    <div className="col-1 pt-1 mr-1">
                      <i className="ti-mobile icon-md" />
                    </div>
                    <div className="col-10 ">
                      <h6 className="d-inline">Phone : <br /> <span className="text-muted">+977-9843698538</span></h6>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-1 pt-1 mr-1">
                      <i className="ti-map-alt icon-md" />
                    </div>
                    <div className="col-10">
                      <h6 className="d-inline">Address :<br /> <span className="text-muted">Kathmandu, Nepal</span></h6>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-1 pt-1 mr-1">
                      <i className="ti-envelope icon-md" />
                    </div>
                    <div className="col-10">
                      <h6 className="d-inline">Email :<br /> <span className="text-muted">shushanta574@gmail.com</span></h6>
                    </div>
                  </div>
                  <ul className="social-icons pt-4">
                    <li className="social-item"><a className="social-link" href="https://www.linkedin.com/in/shushanta-pudasaini-baa7b0134/"><i className="ti-linkedin" aria-hidden="true" /></a></li>
                    <li className="social-item"><a className="social-link" href="https://medium.com/@shushanta574"><i className="ti-text" aria-hidden="true" /></a></li>
                    <li className="social-item"><a className="social-link" href="https://scholar.google.com/citations?user=mBvpJ0MAAAAJ&hl=en&oi=ao"><i className="ti-google" aria-hidden="true" /></a></li>
                    {/* <li className="social-item"><a className="social-link" href="#"><i className="ti-instagram" aria-hidden="true" /></a></li> */}
                    <li className="social-item"><a className="social-link" href="https://github.com/pudasainishushant"><i className="ti-github" aria-hidden="true" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer py-3">
          <div className="container">
            <p className="small mb-0 text-light">
              Thanks For Visiting
            </p>
          </div>
        </footer>
      </div>
    </>
  )
}

export default Home